import React, { ReactElement } from "react";

import styles from "./style.module.scss";

export default function FinalSection(): ReactElement {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    return (
        <section className={styles["mainSection"]}>
            <div className="py-20">
                <h2>What to Do When Your Car Warranty Expires?</h2>
                <p>Get protection with our extended warranty offers.</p>
                <div
                    onClick={() => scrollToTop()}
                    className={styles["btnStyle"]}
                >
                    I'm Ready to Get My Free Quote
                </div>
            </div>
        </section>
    );
}
